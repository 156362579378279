/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
      // All pages
      'common': {
          init: function() {

            /**
             *  Google tracking code
             */
            $('.customer-service .interior-intro a').on( "click", function() {
              ga('send', 'event', 'phonenumber', 'click', 'call');
            });

            $(document).ready(function() {

                    $('.transition-element').delay(400).addClass('hidden');

            });

            /**
             *  Disable page transition on certain link elements
             */
            $('a:not(.disable-transition, [href^="mailto:"], [href^="tel:"], [target="_blank"])').on( 'click', function(e) {
              href = $(this).attr('href');
              e.preventDefault();
                $('.transition-element-exiting').removeClass('hidden');
                setTimeout(function() {
                  window.location.href = href;
                }, 600);
            });
            /**
             *  Add shrink class to header on scroll
             */

            var $body = $('body'),
                  $threshold = 100,
                  $window = $(window);

            $window.scroll(function() {
                  $scroll_position = $window.scrollTop();
                  if ($scroll_position > $threshold) {
                            $body.addClass('shrink');
                   } else {
                            $body.removeClass('shrink');
                   }
            }); 


            /**
             *  Mobile Menu trigger
             */

            $(".menu-trigger").on('click touchstart', function (e) {
                  e.preventDefault();
                  if($body.hasClass('menu-open')) {
                        $body.removeClass('menu-open');
                  } else {
                        $body.addClass('menu-open');
                  }
            });

            $(window).resize(function(e) {
                $body.removeClass('menu-open');
            });
          },
          finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
          }
      },
      // Home page
      'notable_past_projects': {
          init: function() {
            var config = {
              afterContent: function(index){
                var caption = this.$currentTarget.find('span').data('caption');
                this.$instance.find('.caption').remove();
                $('<div class="caption">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
              }
            };
            // init featherlight with config options above
            $( ".before-after" ).on( "click", function(e) {
                galleryID = $(this).data('gallery');
                e.preventDefault();
                $.featherlightGallery( $(".gallery-item-"+galleryID), config );
            });
          }
      },
      'list_of_properties': {
          init: function() {

              // init Isotope
              var $grid = $('.grid').isotope({
                itemSelector: '.grid-item',
                percentPosition: true,

              });
              // layout Isotope after each image loads
              $grid.imagesLoaded().progress( function() {
                $grid.isotope('layout');
              }); 

              $('.display-type-selector a.active').on( 'click', function(e) {
                  e.preventDefault();
              });



              /**
               *  Filtering stuff for "Portfolio"
               */

              // bind filter button click
              $('.property-status-selector').on( 'click', 'a', function(e) {


                $('.portfolio-list').removeClass('animate');
                setTimeout(function() {
                    $('.portfolio-list').addClass('animate');
                },300);
           

                $('.grid-item').removeClass('animate').delay(1000).addClass('animate');
                e.preventDefault();
                var filterValue = $( this ).attr('data-filter');
                filterValue = filterValue;
                $grid.isotope({ filter: filterValue });
                $(".no-results").hide();
              });
              // change active class on buttons
              $('.property-status-selector').each( function( i, linkGroup ) {
                var $linkGroup = $( linkGroup );
                $linkGroup.on( 'click', 'a', function() {
                  $linkGroup.find('.active').removeClass('active');
                  $( this ).addClass('active');
                });
              });

              // Add a no results message if nothing is found on filter
              $grid.on( 'arrangeComplete', function( event, filteredItems ) {
                  var resultCount = filteredItems.length;
                  if(resultCount === 0) {
                      $(".no-results").fadeIn('fast');
                  }
              });

          }
      },
      'slider_block': {
          init: function() {
            $('.slider').slick({
                dots: true,
                arrows: false,
                infinite:false,           
                adaptiveHeight: true
            });
          }
      },
      'property_gallery_block': {
          init: function() {

            var $status = $('.paging-info');
            var $slickElement = $('.property-slider');

            $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
                  //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                  var i = (currentSlide ? currentSlide : 0) + 1;
                  $status.text(i + ' of ' + slick.slideCount);
            });

            $slickElement.slick({
                  lazyLoad: 'ondemand',
                  dots: false,
                  arrows: true,
                  infinite:false,         
                  adaptiveHeight: true,
                  nextArrow: '<div class="slick-next"><i class="icon-right-chevron"></i></div>',
                  prevArrow: '<div class="slick-prev"><i class="icon-left-chevron"></i></div>',
            });

            $slickElement.on('lazyLoaded', function (e, slick, image, imageSource) {
                    image.attr('src',''); //remove source
                    image.css('background-image','url("'+imageSource+'")'); //replace with background instead

                });

          }
      },
      'property_location_block': {

          finalize: function() {

            var mapEl       = $('.property-location .map'),
                  markerURL = mapEl.data('marker'),
                  lng       = mapEl.data('lng'),
                  lat       = mapEl.data('lat'),
                  key       = mapEl.data('key');

            
            function initMap()
            {

                var icon = {
                  url: markerURL,
                  anchor: new google.maps.Point(45.5, 59),
                  scaledSize: new google.maps.Size(99, 118)
                };

                var startPosition = new google.maps.LatLng(lat,lng);
                var myOptions = {
                  zoom: 18,
                  center: startPosition,
                  styles: [
                          {
                              "featureType": "water",
                              "elementType": "geometry",
                              "stylers": [
                                  {
                                      "color": "#e9e9e9"
                                  },
                                  {
                                      "lightness": 17
                                  }
                              ]
                          },
                          {
                              "featureType": "landscape",
                              "elementType": "geometry",
                              "stylers": [
                                  {
                                      "color": "#f5f5f5"
                                  },
                                  {
                                      "lightness": 20
                                  }
                              ]
                          },
                          {
                              "featureType": "road.highway",
                              "elementType": "geometry.fill",
                              "stylers": [
                                  {
                                      "color": "#ffffff"
                                  },
                                  {
                                      "lightness": 17
                                  }
                              ]
                          },
                          {
                              "featureType": "road.highway",
                              "elementType": "geometry.stroke",
                              "stylers": [
                                  {
                                      "color": "#ffffff"
                                  },
                                  {
                                      "lightness": 29
                                  },
                                  {
                                      "weight": 0.2
                                  }
                              ]
                          },
                          {
                              "featureType": "road.arterial",
                              "elementType": "geometry",
                              "stylers": [
                                  {
                                      "color": "#ffffff"
                                  },
                                  {
                                      "lightness": 18
                                  }
                              ]
                          },
                          {
                              "featureType": "road.local",
                              "elementType": "geometry",
                              "stylers": [
                                  {
                                      "color": "#ffffff"
                                  },
                                  {
                                      "lightness": 16
                                  }
                              ]
                          },
                          {
                              "featureType": "poi",
                              "elementType": "geometry",
                              "stylers": [
                                  {
                                      "color": "#f5f5f5"
                                  },
                                  {
                                      "lightness": 21
                                  }
                              ]
                          },
                          {
                              "featureType": "poi.park",
                              "elementType": "geometry",
                              "stylers": [
                                  {
                                      "color": "#dedede"
                                  },
                                  {
                                      "lightness": 21
                                  }
                              ]
                          },
                          {
                              "elementType": "labels.text.stroke",
                              "stylers": [
                                  {
                                      "visibility": "on"
                                  },
                                  {
                                      "color": "#ffffff"
                                  },
                                  {
                                      "lightness": 16
                                  }
                              ]
                          },
                          {
                              "elementType": "labels.text.fill",
                              "stylers": [
                                  {
                                      "saturation": 36
                                  },
                                  {
                                      "color": "#333333"
                                  },
                                  {
                                      "lightness": 40
                                  }
                              ]
                          },
                          {
                              "elementType": "labels.icon",
                              "stylers": [
                                  {
                                      "visibility": "off"
                                  }
                              ]
                          },
                          {
                              "featureType": "transit",
                              "elementType": "geometry",
                              "stylers": [
                                  {
                                      "color": "#f2f2f2"
                                  },
                                  {
                                      "lightness": 19
                                  }
                              ]
                          },
                          {
                              "featureType": "administrative",
                              "elementType": "geometry.fill",
                              "stylers": [
                                  {
                                      "color": "#fefefe"
                                  },
                                  {
                                      "lightness": 20
                                  }
                              ]
                          },
                          {
                              "featureType": "administrative",
                              "elementType": "geometry.stroke",
                              "stylers": [
                                  {
                                      "color": "#fefefe"
                                  },
                                  {
                                      "lightness": 17
                                  },
                                  {
                                      "weight": 1.2
                                  }
                              ]
                          }
                      ]
                };
                var map = new google.maps.Map(document.querySelector(".map"), myOptions);
                var marker = new google.maps.Marker({
                  position: startPosition,
                  map: map,
                  icon: icon
                });

            }
            if (lng && lat) {
              initMap();
            }
            
          }

      }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function(func, funcname, args) {
          var fire;
          var namespace = Sage;
          funcname = (funcname === undefined) ? 'init' : funcname;
          fire = func !== '';
          fire = fire && namespace[func];
          fire = fire && typeof namespace[func][funcname] === 'function';

          if (fire) {
            namespace[func][funcname](args);
          }
      },
      loadEvents: function() {
          // Fire common init JS
          UTIL.fire('common');

          // Fire page-specific init JS, and then finalize JS
          $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
          });

          // Fire common finalize JS
          UTIL.fire('common', 'finalize');
      }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
